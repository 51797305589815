import { Injectable } from "@angular/core";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { debounceTime } from "rxjs/operators";
import { StorageService, STORAGE_CACHE_NAME } from "../core/storage.service";
import type { IStorageFileMeta } from "src/models/models";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";

interface ICachedFiles {
  [relativeName: string]: { uri: string; mtime: number; size: number };
}

@Injectable({
  providedIn: "root",
})
// storage service only used on cordova, otherwise service-worker handles
export class NativeStorageService implements StorageService {
  _name = "Native Storage Service";
  cacheName = STORAGE_CACHE_NAME;
  private basePath: string;
  private cachedFilesList: ICachedFiles = { _version: environment.VERSION as any };
  // use subject to debounce writes to file that keeps log of cached files
  private cachedFilesUpdated$: BehaviorSubject<any> = new BehaviorSubject(true);
  constructor(private fileOpener: FileOpener, private http: HttpClient) {
    console.log("hello native storage service");
  }

  public async init() {
    console.log("[Native Storage] init");
    const { uri } = await Filesystem.getUri({ directory: Directory.Data, path: this.cacheName });
    this.basePath = uri;
    await this.ensureCacheDirectory();
    this.cachedFilesUpdated$.pipe(debounceTime(5000)).subscribe(async () => {
      await this.writeCacheListToFile();
    });
    await this.loadCacheList();
    console.log("cache loaded", this.cachedFilesList);
    this.cachedFilesUpdated$.next(true);
  }

  public async openFile(fileMeta: IStorageFileMeta, mimetype: string) {
    const filepath = `${this.basePath}/${fileMeta.localPath}`;
    await this.fileOpener.open(filepath, mimetype);
  }

  /************************************************************************************
   *  File Caching
   *
   *  current strategy involves writing to files in same directory structure
   *  as wordpres. A separate doc keeps a list of all cached files so that if
   *  the cache is ever deleted the doc will be too (and hence made aware cache empty)
   *
   *  The cache file is written after any storage writes, with the use of a behaviour
   *  subject to allow for debounced writes
   ************************************************************************************/

  /** TODO - include/separate out method to remove any old caches*/
  public async clearCache(): Promise<void> {}

  public async checkFileCached(fileMeta: IStorageFileMeta) {
    return this.cachedFilesList.hasOwnProperty(fileMeta.localPath);
  }

  public async ensureFileCached(fileMeta: IStorageFileMeta) {
    const cached = await this.checkFileCached(fileMeta);
    if (!cached) {
      await this.cacheFiles([fileMeta]);
    }
    return this.convertToLocalUrl(fileMeta);
  }

  public async cacheFiles(filesMeta: IStorageFileMeta[]) {
    const cacheUpdate = {};
    const promises = filesMeta.map((meta) => {
      return new Promise<string>((resolve, reject) => {
        this.downloadFile(meta.downloadUrl).subscribe({
          error: (err) => {
            console.error(err);
            reject(err);
          },
          next: async ({ progress, data }) => {
            if (progress === 100 && data) {
              const res = await Filesystem.writeFile({
                directory: Directory.Data,
                path: `${this.cacheName}/${meta.localPath}`,
                data,
                recursive: true,
              });
              cacheUpdate[meta.localPath] = true;
              resolve(res.uri);
            }
          },
        });
      });
    });
    const urls = await Promise.all(promises);
    // update cache using behaviour subject
    this.cachedFilesList = { ...this.cachedFilesList, ...cacheUpdate };
    this.cachedFilesUpdated$.next(true);
    return urls;
  }

  /**
   * Read cache folder recursively to check what exists
   * TODO - could be split between db entry and re-check methods
   */
  private async loadCacheList(subDir = "") {
    const targetFolder = subDir ? `${this.cacheName}/${subDir}` : this.cacheName;
    const { files } = await Filesystem.readdir({
      directory: Directory.Data,
      path: targetFolder,
    });
    for (const file of files) {
      const { type, mtime, size, uri } = await Filesystem.stat({
        directory: Directory.Data,
        path: `${targetFolder}/${file}`,
      });
      const relativePath = subDir ? `${subDir}/${file}` : file;
      switch (type) {
        case "file":
          this.cachedFilesList[relativePath] = { uri, size, mtime };
          break;
        case "directory":
          await this.loadCacheList(relativePath);
          break;
        default:
          console.error("unknown file type", type);
          break;
      }
    }
  }

  private async writeCacheListToFile() {
    await Filesystem.writeFile({
      directory: Directory.Data,
      path: `${this.cacheName}/cachedFiles.json`,
      data: JSON.stringify(this.cachedFilesList),
      recursive: true,
      encoding: Encoding.UTF8,
    });
  }

  /************************************************************************************
   *  Helper methods
   ************************************************************************************/

  private downloadFile(url: string) {
    const updates$ = new BehaviorSubject({ progress: 0, data: null });
    this.http
      .get(url, {
        responseType: "blob",
        reportProgress: true,
        observe: "events",
      })
      .subscribe(async (event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          const progress = Math.round((100 * event.loaded) / event.total);
          updates$.next({ progress, data: null });
        } else if (event.type === HttpEventType.Response) {
          const base64 = await this.convertBlobToBase64(event.body);
          updates$.next({ progress: 100, data: base64 });
          updates$.complete();
        }
      });
    return updates$;
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  private async ensureCacheDirectory() {
    try {
      await Filesystem.readdir({ directory: Directory.Data, path: this.cacheName });
    } catch (error) {
      if (error.message === "Directory does not exist") {
        await Filesystem.mkdir({ directory: Directory.Data, path: this.cacheName, recursive: true });
      } else {
        throw error;
      }
    }
  }

  // html templates can't show local file:/// images, so convert using cordova webview
  private async convertToLocalUrl(fileMeta: IStorageFileMeta) {
    const fileUrl = `${this.basePath}/${fileMeta.localPath}`;
    return Capacitor.convertFileSrc(fileUrl);
  }
}
