import type { IStorageFileMeta } from "src/models/models";
import { Injectable } from "@angular/core";

export const STORAGE_CACHE_NAME = "wpContentCache_rev20220513";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  _name = "Default Storage Service";

  public async init() {
    return;
  }

  // abstract methods: (require defining in derived services)
  // cache file and return string of local url (for device cache)
  async cacheFiles(filesMeta: IStorageFileMeta[]): Promise<string[]> {
    return filesMeta.map((meta) => meta.downloadUrl);
  }
  async checkFileCached(fileMeta: IStorageFileMeta): Promise<boolean> {
    return true;
  }
  // check if file cached, if not cache and return local url (for device cache)
  async ensureFileCached(fileMeta: IStorageFileMeta): Promise<string> {
    return fileMeta.downloadUrl;
  }
  async openFile(fileMeta: IStorageFileMeta, mimetype: string): Promise<void> {
    // Currently implemented in file-download component
  }
  public async clearCache(): Promise<void> {}
}
