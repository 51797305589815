import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { AnalyticsService } from "src/providers/core/analytics.service";
import { PostStore } from "src/stores/post.store";
import { Capacitor } from "@capacitor/core";
import { StorageService } from "src/providers/core/storage.service";

export const APP_PAGES = [
  {
    title: "Lesson Activities",
    url: "/activities",
    icon: "/assets/images/svgs/activities.svg",
    sizeLg: "6",
    sizeMd: "6",
  },
  {
    title: "Workshops",
    url: "/workshops",
    icon: "/assets/images/svgs/workshops.svg",
    sizeLg: "6",
    sizeMd: "6",
  },
  {
    title: "Weekly Problems",
    url: "/weekly",
    icon: "/assets/images/svgs/weekly.svg",
    sizeLg: "3",
  },
  {
    title: "Search Topics",
    url: "/search",
    icon: "/assets/images/svgs/search.svg",
    sizeLg: "3",
  },
  {
    title: "Help & Discussions",
    url: "/discussions",
    icon: "/assets/images/svgs/discussions.svg",
    sizeLg: "3",
  },
  {
    title: "Downloads",
    url: "/downloads",
    icon: "/assets/images/svgs/downloads.svg",
    sizeLg: "3",
  },

  // {
  //   title: "Stats",
  //   url: "/stats",
  //   icon: "/assets/images/svgs/stats.svg"
  // }
];
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  public isLoading = true;
  homePage = {
    title: "Home",
    url: "/home",
    icon: "/assets/images/svgs/home.svg",
  };
  public appPages = [...[this.homePage], ...APP_PAGES];
  constructor(
    private platform: Platform,
    // call analytics to initiate tracking
    public analytics: AnalyticsService,
    public postStore: PostStore,
    private storageService: StorageService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      // storage init
      await this.storageService.init();
      console.log("storage init complete", this.storageService._name);
      // store init
      this.postStore.init();

      // cordova mobile init
      if (Capacitor.isNativePlatform()) {
        console.log("[Native] init");
        StatusBar.setStyle({ style: Style.Default });
        await SplashScreen.hide();
      }

      this.isLoading = false;
    });
  }
}
