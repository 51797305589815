// code from http://meumobi.github.io/ionic/pwa/2018/04/12/using-analytics-ionic-app-pwa-native.html
// and https://www.geekcafe.com/angular/2018/02/28/add-google-analytics-to-your-angular-spa-app-in-a-few-simple-steps/
import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
// note, type is imported and should be able to be set as UniversalAnalytics.ga, or even just
// not declare however this seems to throw ts errors
declare const ga: Function;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private subscription: Subscription;
  constructor(private router: Router) {
    this.startTrackerWithId(environment.analyticsID);
    this.trackRouterChanges();
  }
  startTrackerWithId(id: string) {
    ga("create", {
      storage: "none",
      trackingId: id,
      clientId: localStorage.getItem("ga:clientId"),
    });
    ga("set", "checkProtocolTask", null);
    ga("set", "transportUrl", "https://www.google-analytics.com/collect");
    ga(function (tracker) {
      if (!localStorage.getItem("ga:clientId")) {
        localStorage.setItem("ga:clientId", tracker.get("clientId"));
      }
    });
  }
  trackRouterChanges() {
    if (!this.subscription) {
      // subscribe to any router navigation and once it ends, write out the google script notices
      this.subscription = this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          // this will find & use the ga function pulled via the google scripts
          try {
            ga("set", "page", e.urlAfterRedirects);
            ga("send", "pageview");
          } catch {
            console.log("tracking not found - make sure you installed the scripts");
          }
        }
      });
    }
  }
  stopTrackingRouterChanges() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  trackView(viewName: string) {
    ga("set", "page", viewName);
    ga("send", "pageview");
  }
  trackEvent(category: eventCategories, action: string, label?: string, value?: string) {
    ga("send", "event", {
      eventCategory: category,
      eventLabel: label,
      eventAction: action,
      eventValue: value,
    });
  }
}
type eventCategories = "download" | "post-view";
