import type { Timestamp } from "@angular/fire/firestore";
import { Capacitor } from "@capacitor/core";
import { environment } from "src/environments/environment";
import { StorageService } from "src/providers/core/storage.service";
// default post simply contains loading message that can be seen in title bar
// used when tryign to load from slug
const postDefaults: Partial<IPost> = {
  title: "Loading...",
  content: "",
};
/*************************************************************************
 *    Class
 ************************************************************************/
export class Post {
  values: IPost;
  // cva replaces regular url with local after caching so need to track
  _featuredCachedUrl: string;
  _postImagesCached: boolean;
  _postCached: boolean;

  constructor(values: IPost | null, private storageService: StorageService) {
    this.values = { ...postDefaults, ...values };
    if (values) {
      this.ensureFeaturedCached();
    }
  }

  async ensureFeaturedCached() {
    if (this.values.featured && !this._featuredCachedUrl) {
      this._featuredCachedUrl = await this.storageService.ensureFileCached(this.values.featured);
    }
  }

  // check post images in cache and replace localpath with cached value for later
  // post replacement
  async ensurePostImagesCached() {
    if (!this._postImagesCached) {
      try {
        const promises = this.values.links.images.map(async (imgMeta) => {
          const url = await this.storageService.ensureFileCached(imgMeta);
          imgMeta.localPath = url;
          this._postImagesCached = true;
          return imgMeta;
        });
        this.values.links.images = await Promise.all(promises);
      } catch (error) {
        console.error("could not cache post images", this.values);
      }
    }
  }

  // method called to cache post
  async ensurePostCached() {
    if (!this._postCached) {
      await this.ensureFeaturedCached();
      await this.ensurePostImagesCached();
      if (Capacitor.isNativePlatform()) {
        this.replaceCachedContentUrls();
      }
      this._postCached = true;
      console.log(`post [${this.values.id}] is cached`);
    }
  }

  // replace any urls in post content for cached images
  public replaceCachedContentUrls() {
    let content = this.values.content;
    this.values.links.images.forEach((imgMeta) => {
      // define text to replace regex separately as cannot pass variable to replace function
      const regex = new RegExp(imgMeta._originalUrl, "gi");
      content = content.replace(regex, imgMeta.localPath);
    });
    this.values.content = content;
  }
}

/*************************************************************************
 *    Interfaces
 ************************************************************************/
export interface IPost {
  _created: string;
  _modified: string;
  _source: "cache" | "server";
  date: string;
  featured: IStorageMeta | null;
  content: string;
  id: number;
  links: IPostLinks;
  categories: number[];
  slug: string;
  tags: number[];
  title: string;
}

interface IPostLinks {
  images: IStorageMeta[];
  internalUrls: string[];
  externalUrls: string[];
  files: IStorageMeta[];
  _self: string;
}

interface IStorageMeta {
  _originalUrl: string;
  bucket: string;
  contentType: string;
  downloadUrl: string;
  localPath: string;
  md5Hash: string;
  mediaLink: string;
  name: string;
  selfLink: string;
  size: string;
}
