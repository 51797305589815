import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { SwUpdate } from "@angular/service-worker";

@Injectable({
  providedIn: "root",
})
export class ServiceWorkerService {
  // note, to get types would need to make a static import (currently dynamic)
  // only 1kb so efficiency won't drop much, but want to keep for example of how to do
  persisted: boolean;
  constructor(private updates: SwUpdate, private toastCtrl: ToastController) {}
  public async init() {
    this._requestPersistentStorage();
    this._subscribeToSwUpdates();
  }
  async activateUpdate() {
    // this.wb.addEventListener("controlling", (event) => {
    //   console.log("sw now controlling");
    //   window.location.reload();
    // });
    // // Send a message telling the service worker to skip waiting.
    // // This will trigger the `controlling` event handler above.
    // this.wb.messageSW({ type: "SKIP_WAITING" });
    // // note, this doesn't always fire automatically/straight away, tried fallback installing
    // // screen but didn't provide good experience
  }
  async promptUpdate() {
    console.log("update available");
    const toast = await this.toastCtrl.create({
      buttons: [
        {
          icon: "refresh-outline",
          text: "Reload",
          handler: () => {
            location.reload();
          },
        },
      ],
      message: "app has been updated",
    });
    await toast.present();
    await toast.onDidDismiss();
    this.activateUpdate();
  }

  /******************************************************************************************
   *  Private Methods
   *****************************************************************************************/

  private _subscribeToSwUpdates() {
    this.updates.unrecoverable.subscribe(async (event) => {
      await this.promptUpdate();
      // notifyUser(
      //   'An error occurred that we cannot recover from:\n' +
      //   event.reason +
      //   '\n\nPlease reload the page.'
      // );
    });
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case "VERSION_DETECTED":
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case "VERSION_READY":
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          break;
        case "VERSION_INSTALLATION_FAILED":
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }
  private _requestPersistentStorage() {
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then((granted) => {
        if (granted) {
          this.persisted = true;
        } else {
          this.persisted = false;
        }
      });
    }
    console.log("storage persisted?", this.persisted);
  }
}
