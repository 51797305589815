import { NgModule } from "@angular/core";
import * as Sentry from "sentry-cordova";
import { ServiceWorkerModule } from "@angular/service-worker";
import { StorageService } from "src/providers/core/storage.service";
import { PWAStorageService } from "src/providers/pwa/storage.service";
console.log("hello pwa");
Sentry.init({
  dsn: "https://4b7f66c2b5d74c6caa2229ae67b5de37@sentry.io/1217363",
});
@NgModule({
  imports: [
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [{ provide: StorageService, useClass: PWAStorageService }],
})
export class PlatformModule {
  constructor() {
    console.log("hello pwa module");
  }
}
