import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";

// see: https://blog.ionicframework.com/how-to-lazy-load-in-ionic-angular/
export class SimpleLoadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => any): Observable<any> {
    if (route.data && route.data.preload) {
      return load();
    }
    return of(null);
  }
}
