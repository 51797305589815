import { Capacitor } from "@capacitor/core";
import { IEnvironment, IEnvironmentCustom } from "src/models/models";
import { baseEnvironment } from "./environment.base";

console.log("[Production] environment");
// when running production build this will replace environment.ts
// note, 2 different sites need to be distinguished, as both run production buildsfire
const stagingEnv: IEnvironmentCustom = {
  analyticsID: "UA-120646269-3",
  firebase: {
    apiKey: "AIzaSyCVv7uA1TLuQv7oopr7Mmfbx7mWJHQxArE",
    authDomain: "aiminghighapp-staging.firebaseapp.com",
    databaseURL: "https://aiminghighapp-staging.firebaseio.com",
    projectId: "aiminghighapp-staging",
    storageBucket: "aiminghighapp-staging.appspot.com",
    messagingSenderId: "704138280946",
    appId: "1:704138280946:web:5350c0fe9e60b3f9",
  },
};
const productionEnv: IEnvironmentCustom = {
  analyticsID: "UA-120646269-2",
  firebase: {
    apiKey: "AIzaSyBCuyuVOhs80Bk00DuGXqrjdpxPYz6qJPU",
    authDomain: "aiminghighapp.firebaseapp.com",
    databaseURL: "https://aiminghighapp.firebaseio.com",
    projectId: "aiminghighapp",
    storageBucket: "aiminghighapp.appspot.com",
    messagingSenderId: "228182150461",
    appId: "1:228182150461:web:629e6b5e0f5fc163",
  },
};
const productionSites = ["aimssec.app", "aiminghighapp.firebaseapp.com"];
// switch prod and staging sites, remove console logs for prod
let siteEnv = stagingEnv;
// use production db on sites listed above or cordova (app version)
if (productionSites.includes(location.host) || Capacitor.isNativePlatform()) {
  siteEnv = productionEnv;
  // console.log("Production site - logs not shown");
  // console.log = () => null;
}
export const environment: IEnvironment = {
  ...baseEnvironment,
  ...siteEnv,
  production: true,
};
