import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { observable, action, computed } from "mobx-angular";
import { Observable } from "rxjs";
import { Post, IPost } from "src/models/post.model";
import { AnalyticsService, DbService } from "src/providers/core";
import { StorageService } from "src/providers/core/storage.service";

// tried using with remotedev but not successful, could not later inject the store
@Injectable({
  providedIn: "root",
})
export class PostStore {
  @observable posts: Post[] = [];
  @observable activePost: Post = new Post(null, this.storageService);
  @observable filteredPosts: Post[] = [];
  @action setActivePost(post: Post) {
    this.activePost = post;
    this.activePost.ensurePostCached();
    console.log("active post", post.values);
    this.analytics.trackEvent("post-view", this.activePost.values.slug);
  }
  // when active post slug set, check if already loaded, if not find it
  @action setActivePostSlug(slug: string) {
    if (!this.activePost || this.activePost.values.slug !== slug) {
      this.setPostBySlug(slug);
    }
  }
  @computed get workshopPosts() {
    // select only professional development categories 8 and 293
    return this._filterIncludes(this.posts, [8]);
  }
  @computed get problemPosts() {
    // exclude professional development categories 8 and weekly 293
    // (note, could also include 110 which is 'lesson activities', but not all tagged)
    return this._filterExcludes(this.posts, [8, 293]);
  }
  @computed get weeklyPosts() {
    return this._filterIncludes(this.posts, [293]);
  }
  @computed get postsBySlug() {
    const posts = {};
    this.posts
      .sort((a, b) => (a.values.slug > b.values.slug ? 1 : -1))
      .forEach((p) => (posts[p.values.slug] = p.values));
    return posts;
  }
  constructor(
    public db: DbService,
    public router: Router,
    private analytics: AnalyticsService,
    private storageService: StorageService
  ) {}
  public init() {
    console.log("post store init");
    const obs = this.db.getCollection("posts", "_modified") as Observable<IPost[]>;
    obs.subscribe((posts) => {
      const sorted = posts.filter((p) => p.hasOwnProperty("date")).sort((a, b) => (b.date > a.date ? 1 : -1));
      this.setPosts(sorted.map((post) => new Post(post, this.storageService)));
    });
  }

  @action setPosts(posts: Post[]) {
    this.posts = posts;
  }
  public async setPostBySlug(slug: string) {
    // TODO - use computed posts by slug
    const matches = await this.db.queryCollection("posts", "slug", "==", slug);
    if (matches.length > 0) {
      const p = matches[0] as IPost;
      this.setActivePost(new Post(p, this.storageService));
    } else {
      // post not found, go back to home page
      console.log("slug not found", slug);
      this.router.navigate(["/"]);
      throw new Error(`slug not found: ${slug}`);
    }
    return this.activePost;
  }

  // simple filter to pass through set of phase and then topic category filters
  // *** TODO - make more generalised
  public filterActivities(phaseCategoryNumber?: number, topicTagNumber?: number) {
    const phasePosts = phaseCategoryNumber
      ? this._filterIncludes(this.problemPosts, [phaseCategoryNumber])
      : this.problemPosts;
    const topicPosts = topicTagNumber ? this._filterTagsIncludes(phasePosts, [topicTagNumber]) : phasePosts;
    this.filteredPosts = topicPosts;
    console.log("filtered", this.filteredPosts.length);
    return this.filteredPosts;
  }
  private _filterTagsIncludes(posts: Post[] = [], tags: number[]) {
    return posts.filter((post) => post.values.tags && tags.every((tag) => post.values.tags.includes(tag)));
  }

  // filter to include those with all matching categories
  private _filterIncludes(posts: Post[] = [], categories: number[]) {
    return posts.filter(
      (post) => post.values.categories && categories.every((category) => post.values.categories.includes(category))
    );
  }

  // filter to exclude those which all matching categories
  private _filterExcludes(posts: Post[] = [], categories: number[]) {
    return posts.filter(
      (post) =>
        post.values.categories &&
        categories.every((category) => post.values.categories && !post.values.categories.includes(category))
    );
  }
}
