import { NgModule, Provider } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { HttpClientModule } from "@angular/common/http";
// Component
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "src/environments/environment";
// State Management
import { MobxAngularModule } from "mobx-angular";
// Firebase
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { enableIndexedDbPersistence, getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getAuth, provideAuth } from "@angular/fire/auth";
// Custom
import { PlatformModule } from "src/modules";
// Native
import { Capacitor } from "@capacitor/core";
import { NativeStorageService } from "src/providers/native/storage.service";
import { StorageService } from "src/providers/core/storage.service";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";

const providers: Provider[] = [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }];

// HACK - not injecting from platform module so override native storage here
// TODO - probably easiest to just provide app.native.module.ts and app.pwa.module.ts to override
if (Capacitor.isNativePlatform()) {
  console.log("applying native storage");
  providers.push({ provide: StorageService, useClass: NativeStorageService }, InAppBrowser, FileOpener);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    // provide modular style for AppCheck, see app.browser/server
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    provideAuth(() => getAuth()),
    MobxAngularModule,
    PlatformModule,
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
