import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SimpleLoadingStrategy } from "./SimpleLoadingStrategy";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("src/pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "home",
    loadChildren: () => import("src/pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "p/:slug",
    loadChildren: () => import("src/pages/post-detail/post-detail.module").then((m) => m.PostDetailPageModule),
  },
  {
    path: "activities",
    loadChildren: () => import("src/pages/activities/activities.module").then((m) => m.ActivitiesPageModule),
    data: {
      preload: true,
    },
  },
  {
    path: "workshops",
    loadChildren: () => import("src/pages/workshops/workshops.module").then((m) => m.WorkshopsPageModule),
    data: {
      preload: true,
    },
  },
  {
    path: "search",
    loadChildren: () => import("src/pages/search/search.module").then((m) => m.SearchPageModule),
    data: {
      preload: true,
    },
  },
  {
    path: "weekly",
    loadChildren: () => import("src/pages/weekly/weekly.module").then((m) => m.WeeklyPageModule),
    data: {
      preload: true,
    },
  },
  {
    path: "stats",
    loadChildren: () => import("src/pages/stats/stats.module").then((m) => m.StatsPageModule),
  },
  {
    path: "discussions",
    loadChildren: () => import("src/pages/discussions/discussions.module").then((m) => m.DiscussionsPageModule),
  },
  {
    path: "downloads",
    loadChildren: () => import("src/pages/downloads/downloads.module").then((m) => m.DownloadsPageModule),
  },
  {
    path: "privacy",
    loadChildren: () => import("src/pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
  },
];

// add support for loading different slugs on same page
@NgModule({
  providers: [SimpleLoadingStrategy],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SimpleLoadingStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
