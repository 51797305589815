import { Injectable } from "@angular/core";
import {
  Firestore,
  collection,
  query,
  where,
  orderBy,
  getDocsFromCache,
  getDocsFromServer,
  startAfter,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DbService {
  // *** note, should make this private and force use of own methods instead
  // own methods should populate meta keys and timestamps
  results$: any = {};
  constructor(public firestore: Firestore) {}

  // NOTE, REQUIRES modified FIELD ON ALL DOCS TO FUNCTION PROPERLY
  // Must ensure querying against processed and not raw docs
  getCollection(endpoint: IWPEndpoint, orderByField: string) {
    const collectionName = getCollectionMapping(endpoint);
    this.results$[endpoint] = new Observable<any[]>((subscriber) => {
      let docsQuery = query(collection(this.firestore, collectionName), orderBy(orderByField));
      getDocsFromCache(docsQuery).then((records) => {
        const cachedDocs = records.docs.map((d) => ({ ...d.data(), _source: "cache" }));
        const lastCached = records.docs[records.size - 1];
        console.log(`[${cachedDocs.length}] cached records`);
        subscriber.next(cachedDocs);
        if (lastCached) {
          docsQuery = query(collection(this.firestore, collectionName), orderBy(orderByField), startAfter(lastCached));
        }
        getDocsFromServer(docsQuery).then((serverRes) => {
          const serverDocs = serverRes.docs.map((d) => ({ ...d.data(), _source: "server" }));
          console.log(`[${serverDocs.length}] server records`);
          const merged = this.mergeData(cachedDocs, serverDocs, "id");
          subscriber.next(merged);
        });
      });
    });
    return this.results$[endpoint];
  }

  // use firestore querystring to fetch doc. First check cache and return if found, otherwise check live
  async queryCollection(
    endpoint: IWPEndpoint,
    field: string,
    // note, importing whereFilterOp typing breaks AOT
    operator: any,
    value: any
  ) {
    const collectionName = getCollectionMapping(endpoint);
    const docsQuery = query(collection(this.firestore, collectionName), where(field, operator, value));
    let res = await getDocsFromCache(docsQuery);
    if (res.empty) {
      res = await getDocsFromServer(docsQuery);
    }
    return res.docs.map((d) => d.data());
  }

  // not currently implemented, but may want some method to ensure cached data not stale
  // e.g. case when problem edited or deleted and modified field not changed
  private async refreshCache(endpoint: IWPEndpoint, orderBy: string) {
    // const docs = await this.afs.firestore.collection(endpoint).orderBy(orderBy).get({ source: "server" });
    // return docs;
  }

  // currently no easy method to do this with firebase, would have to disable persistance at start
  private async deleteCache() {}

  // merge two object arrays by a given field (e.g. id or key)
  private mergeData(oldDocs: any[], newDocs: any[], mergeField: string) {
    const json = {};
    oldDocs.forEach((d) => {
      json[d[mergeField]] = d;
    });
    newDocs.forEach((d) => {
      json[d[mergeField]] = d;
    });
    return Object.values(json);
  }
}

// TODO duplicate of functions
type IWPEndpoint =
  | "posts"
  | "categories"
  | "tags"
  | "pages"
  | "types"
  | "comments"
  | "taxonomies"
  | "statuses"
  | "media";

const DBMapping = {
  categories: "categories_rev20220513",
  comments: "comments_rev20220513",
  posts: "posts_rev20220513",
  tags: "tags_rev20220513",
  // not currently used
  media: "media_rev20220513",
  pages: "pages_rev20220513",
  statuses: "statuses_rev20220513",
  taxonomies: "taxonomies_rev20220513",
  types: "types_rev20220513",
} as const;

// https://stackoverflow.com/questions/53662208/types-from-both-keys-and-values-of-object-in-typescript
export type IDBEndpoint = typeof DBMapping[IWPEndpoint];

/**
 *
 * TODO - Duplicated in frontend
 */
export function getCollectionMapping(wpEndpoint: IWPEndpoint): IDBEndpoint {
  return DBMapping[wpEndpoint] || (wpEndpoint as IDBEndpoint);
}
