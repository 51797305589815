import { Injectable } from "@angular/core";
import { upgradeUrl } from "src/hacks/ahsite.hacks";
import { StorageService, STORAGE_CACHE_NAME } from "../core/storage.service";
import { ServiceWorkerService } from "./service-worker.service";
import type { IStorageFileMeta } from "src/models/models";

interface ICachedFiles {
  [filelocation: string]: boolean;
}

@Injectable({
  providedIn: "root",
})
export class PWAStorageService implements StorageService {
  _name = "PWA Storage Service";
  cacheName = STORAGE_CACHE_NAME;

  private cachedFilesList: ICachedFiles = {};
  // keep track of files or collections that have been cached
  // if file to be downloaded, save the url as the cacheKey and set shouldFetch true
  // currently one single cache for any url links. force used for dev purposes
  constructor(private sw: ServiceWorkerService) {}
  public async init() {
    console.log("[PWA storage] init");
    await this.sw.init();
    await this.loadCacheList();
  }

  /** TODO - include/separate out method to remove any old caches*/
  public async clearCache(): Promise<void> {}

  private async loadCacheList() {
    try {
      const cache = await window.caches.open(this.cacheName);
      const list = {};
      const cacheKeys = await cache.keys();
      cacheKeys.forEach((req) => (list[req.url] = true));
      this.cachedFilesList = list;
    } catch (error) {
      console.error(error);
    }
    console.log("initial cache loaded:", this.cachedFilesList);
  }
  async listCachedFiles() {
    return this.cachedFilesList;
  }

  async cacheFiles(filesMeta: IStorageFileMeta[]) {
    const cache = await window.caches.open(this.cacheName);
    const cacheUpdate = {};
    const requests = filesMeta.map((file) => this.generateRequest(file.downloadUrl));
    for (const req of requests) {
      try {
        await cache.add(req);
        cacheUpdate[req.url] = true;
      } catch (error) {
        console.log("could not add to cache", req);
      }
    }
    this.cachedFilesList = { ...this.cachedFilesList, ...cacheUpdate };
    await this.writeCacheListToFile();
    return filesMeta.map((meta) => meta.downloadUrl);
  }
  async checkFileCached(filemeta: IStorageFileMeta) {
    const request = this.generateRequest(filemeta.downloadUrl);
    return this.cachedFilesList[request.url] ? true : false;
  }
  async writeCacheListToFile() {
    // not required for pwa methods as window cache stores info
  }

  async ensureFileCached(filemeta: IStorageFileMeta) {
    const cached = await this.checkFileCached(filemeta);
    if (!cached) {
      await this.cacheFiles([filemeta]);
    }
    return filemeta.downloadUrl;
  }

  async openFile(fileMeta: IStorageFileMeta, mimetype: string) {
    // Currently implemented in file-download component
  }

  // note, wp site must have cors enabled and localhost use plug https://chrome.google.com/webstore/detail/allow-control-allow-origi/nlfbmbojpeacfghkpbjhddihlkkiljbi
  generateRequest(url: string) {
    return new Request(upgradeUrl(url), { mode: "cors" });
  }
}
